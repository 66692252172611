import { useState, createContext, useContext, useCallback, ReactNode } from "react";
import { NotificationUi } from "./NotificationUi";

type notifyProps = ({
  show,
  label,
  type,
}: {
  show?: boolean;
  label?: string;
  type?: "info" | "success" | "error";
}) => void;

const context = createContext(undefined as undefined | notifyProps);
const { Provider } = context;

export function useNotify() {
  const c = useContext(context);
  if (!c) throw new Error("Can't use useNotify outside of a NotificationProvider");
  return c;
}

export function NotificationProvider({ children }: { children: ReactNode }) {
  const [notification, setNotify] = useState({
    show: false,
    label: "",
    type: "success" as "info" | "success" | "error",
  });
  let timeouts = [];

  function clearAllTimeout() {
    for (let i = 0; i < timeouts.length; i++) {
      clearTimeout(timeouts[i]);
    }
    timeouts = [];
  }

  const hide = () => {
    setNotify((n) => ({ ...n, show: false }));
    clearAllTimeout();
  };

  const notify = useCallback(({ show = true, label = "resource.notification.error", type = "error" }) => {
    setNotify({ show, label, type });
    clearAllTimeout();
    const id = setTimeout(() => {
      hide();
      clearTimeout(id);
    }, 5000);

    timeouts.push(id);
  }, []);

  return (
    <Provider value={notify}>
      <NotificationUi show={notification.show} type={notification?.type} onClick={hide}>
        {notification.label}
      </NotificationUi>

      {children}
    </Provider>
  );
}
