import { Box } from "reflexbox";

type BoxProps = React.ComponentProps<typeof Box>;

export const Title = (props: BoxProps) => (
  <Box
    as="h2"
    fontFamily="Poppins"
    fontSize={3}
    color="primary.dark_to_white"
    style={{ cursor: "default" }}
    {...props}
  />
);
