import { Flex } from "reflexbox";

export function IndicatorGroupe() {
  return (
    <div>
      <Flex
        height="23px"
        width="23px"
        bg="groupe"
        color="white.main"
        sx={{ borderRadius: "50%" }}
        alignItems="center"
        justifyContent="center"
      >
        G
      </Flex>
    </div>
  );
}
