import { useState } from "react";
import { useEffect } from "react";
import { Box } from "reflexbox";
import { useThemeUI } from "theme-ui";

export function Tooltip({
  text,
  children,
  bg = "primary.blue_to_white",
  position = "right",
  transitionDelay = "500ms",
  transitionDuration = "200ms",
  ...props
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setShow(false);
    }, 2000);

    return () => clearTimeout(id);
  }, [show]);

  const getPositionStyle = () => {
    switch (position) {
      case "right":
        return {
          top: "calc(50% - 13px)",
          left: "130%",
          bottom: "calc(50% + 13px)",
        };
      case "left":
        return {
          right: "130%",
          top: "calc(50% - 13px)",
          bottom: "calc(50% + 13px)",
        };
      case "top":
        return {};
      case "bottom":
        return { top: "calc(50% + 13px)" };

      default:
        break;
    }
  };
  const getArrowStyle = () => {
    const { theme }: any = useThemeUI();
    switch (position) {
      case "right":
        return {
          left: "-4px",
          width: 0,
          height: 0,
          borderTop: "5px solid transparent",
          borderBottom: "5px solid transparent",
          borderRight: `5px solid ${theme?.colors?.primary?.blue_to_white}`,
        };
      case "left":
        return {
          right: "-4px",
          width: 0,
          height: 0,
          borderTop: "5px solid transparent",
          borderBottom: "5px solid transparent",
          borderLeft: `5px solid ${theme?.colors?.primary?.blue_to_white}`,
        };
      case "top":
        return {};
      case "bottom":
        return {};

      default:
        break;
    }
  };
  return (
    <span
      style={{ position: "relative", display: "block" }}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      {...props}
    >
      <Box
        onMouseEnter={() => setShow(false)}
        p="2px"
        height="min-content"
        minHeight="26px"
        paddingX={1}
        backgroundColor={bg}
        color="primary.white_to_dark"
        display="flex"
        justifyContent="center"
        alignItems="center"
        fontSize="12px"
        sx={{
          opacity: show ? "1" : "0",
          visibility: show ? "visible" : "hidden",
          borderRadius: "5px",
          position: "absolute",
          zIndex: 2001,
          whiteSpace: "nowrap",
          transitionProperty: "visibility, opacity",
          transitionDuration: transitionDuration,
          transitionDelay: transitionDelay,
          transitionTimingFunction: "ease-out",
          boxShadow: "0 0 0.5rem rgba(255, 255, 255, 0.3)",
          animation: "bubble 2s ease-in-out infinite",
          ...getPositionStyle(),
          "::before": {
            content: "''",
            position: "absolute",
            ...getArrowStyle(),
          },
        }}
      >
        {text}
      </Box>
      {children}
    </span>
  );
}
