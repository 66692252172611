import { Box } from "reflexbox";

export const Bounced = ({ scale = "1.03", ...props }) => (
  <Box
    {...props}
    sx={{
      cursor: "pointer",
      borderRadius: "24px",
      ":hover": {
        transform: `scale(${scale})`,
      },
      transition: "all linear 100ms",
    }}
  />
);
