import { Admin } from "@kards/types";
import { createContext, useContext } from "react";
import { firebase } from "../firebase";

const AppContext = createContext(undefined);

export const AppProvider = AppContext.Provider;

export function useRole(): Admin["role"] {
  const { token } = useContext(AppContext);
  return token?.claims?.role;
}

export function useToken(): firebase.auth.IdTokenResult {
  const { token } = useContext(AppContext);
  return token;
}

export function useApp() {
  const app = useContext(AppContext);
  return app;
}

export function useMenu() {
  const { menuWrapped, setMenuWrapped } = useContext(AppContext);
  return [menuWrapped, setMenuWrapped] as const;
}
