const swUrl = `${process.env.PUBLIC_URL}/custom-sw.js`; // In this line
export function register() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker.register(swUrl).then(reg => {
        if (!navigator.serviceWorker.controller) {
          return;
        }

        if (reg.waiting) {
          console.log("inside reg.waiting");
          return;
        }

        if (reg.installing) {
          console.log("inside reg.installing");
          return;
        }

        reg.addEventListener("updatefound", () => {
          const worker = reg.installing;
          console.log("---- update found ----");

          worker.addEventListener("statechange", () => {
            if (worker.state === "installed") {
              worker.postMessage({ action: "skipWaiting" });
              console.log(" --- need reload --- ");
              // window.location.reload();
              // Here you can notify the user, that a new version exist.
              // Show a toast view, asking the user to upgrade.
              // The user accepts.
              // Send a message to the sw, to skip wating.
            }
          });
        });
      });
    });
  }
}
