import { useEffect, useRef } from "react";

export function VideoPlayer({ src, ...props }: React.VideoHTMLAttributes<HTMLVideoElement>) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (window.Hls.isSupported() && videoElement) {
      const hls = new window.Hls();
      hls.loadSource(src);
      hls.attachMedia(videoElement);
    }
  }, [videoRef.current]);

  return <video ref={videoRef} src={src} {...props} />;
}
