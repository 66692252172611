import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "proxy-polyfill";

import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import WebFont from "webfontloader";
import { register } from "./serviceWorker";

WebFont.load({
  google: {
    families: [
      "Arial:sans-serif",
      "Abril Fatface:cursive",
      "Antic Slab:serif",
      "Anton:sans-serif",
      "Arimo:sans-serif",
      "Arvo:sans-serif",
      "Courgette:cursive",
      "Dosis:sans-serif",
      "Expletus Sans:cursive",
      "Karla:sans-serif",
      "Inconsolata:sans-serif",
      "Josefin Sans:sans-serif",
      "Lato:sans-serif",
      "Lobster:sans-serif",
      "ITC American Typewriter:sans-serif",
      "Mulish:sans-serif",
      "Montserrat:ital,wght@1,100,sans-serif",
      "Nova Round:cursive",
      "Oswald:sans-serif",
      "Pacifico:cursive",
      "Palanquin:sans-serif",
      "Play:sans-serif",
      "Poppins:sans-serif",
      "Poppins:600",
      "Quicksand:sans-serif",
      "Raleway:sans-serif",
      "Roboto",
      "Roboto:sans-serif",
      "Roboto Slab:serif",
      "Shadows Into Light:cursive",
      "Sora:sans-serif",
      "Spartan:sans-serif",
      "Source Sans Pro:sans-serif",
      "Titillium Web:sans-serif",
      "Yanone Kaffeesatz:sans-serif",
      "Yatra One:cursive",
      "Yeon Sung:cursive",
    ],
  },
});

ReactDOM.render(<App />, document.getElementById("root"));
register();
