const windowT: any = window;

export const firebaseConfig = {
  apiKey: windowT.kardsConfiguration.apiKey,
  authDomain: windowT.kardsConfiguration.authDomain,
  databaseURL: windowT.kardsConfiguration.databaseURL,
  projectId: windowT.kardsConfiguration.projectId,
  storageBucket: windowT.kardsConfiguration.storageBucket,
  messagingSenderId: windowT.kardsConfiguration.messagingSenderId,
  appId: windowT.kardsConfiguration.appId,
  measurementId: windowT.kardsConfiguration.measurementId,
};
