import { Box } from "reflexbox";
import { useField } from "react-final-form";
import { Label } from "./Label";
import { Input } from "./Input";
import { ErrorField } from "./ErrorField";

export type NumberFieldProps = {
  name: string;
  label: string;
  required?: boolean;
  endAdornment?: React.ReactNode;
  fullWidth?: boolean;
} & React.ComponentProps<typeof Input>;

export function NumberField({
  name,
  label,
  required,
  endAdornment,
  width,
  fullWidth = true,
  ...props
}: NumberFieldProps) {
  const { input, meta } = useField(name, { parse: (a) => Number(a) });

  return (
    <Box sx={{ position: "relative" }} width={width ? width : fullWidth ? "540px" : "215px"}>
      <label>
        <Label color={props.color}>{`${label}${required ? "*" : ""}`}</Label>
        <Input {...input} type="number" {...props} />
        {endAdornment && (
          <Box sx={{ position: "absolute", top: "34px", right: "35px" }} fontWeight="500" color="secondary.dark">
            {endAdornment}
          </Box>
        )}
      </label>
      <ErrorField meta={meta} />
    </Box>
  );
}
