import { Flex } from "reflexbox";
import { useField } from "react-final-form";
import { Label } from "./Label";
import { Input } from "./Input";
import { ErrorField } from "./ErrorField";
import TimeInput from "react-simple-timefield";
import styled from "styled-components";

export type NumberFieldProps = {
  name: string;
  label: string;
  required?: boolean;
} & React.ComponentProps<typeof Input>;

export function TimeField({ name, label, required }: NumberFieldProps) {
  const { input, meta } = useField(name);

  return (
    <Flex
      sx={{ position: "relative" }}
      alignItems="center"
      justifyContent="center"
    >
      <Label mb="0" mr="3px">{`${label}${required ? "*" : ""}`}</Label>
      <label>
        <TimeInputStyled
          style={{}}
          value={input.value}
          onChange={input.onChange}
          colon=":" // {String}   default: ":"
        />
        <ErrorField meta={meta} />
      </label>
    </Flex>
  );
}

const TimeInputStyled = styled(TimeInput)`
  ${({ theme }) => `
    border-radius: 3px;
    background-color: #EDEEF4;
    padding: 4px 10px;
    outline: none;
    font-size: .9em;
    color: ${
      theme?.palette ? theme?.palette?.secondary?.dark : "#777777"
    } !important;
    width: 80px!important;
    box-sizing: border-box;
    ::placeholder {
      color: ${theme?.palette?.secondary?.main};
    }
  `}
`;
