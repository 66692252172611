import { Box, Flex } from "reflexbox";
import { ImWarning } from "react-icons/im";
import { Button, ButtonProps } from "./Button";
import { Modal } from "./Modal";
import { Text } from "./Text";
import { useModal } from "src/helpers/useModal";
import { ReactNode } from "react";

type ButtonDeleteProps = {
  onDelete: (e?: any) => void;
  modalText?: string;
  btnText?: string;
  children?: React.ReactNode;
  renderButton?: (obj: { openModal: ReturnType<typeof useModal>["openModal"] }) => ReactNode;
  widthModal?: any;
} & ButtonProps;

export function ButtonDelete({
  modalText = "Êtes vous sûr de vouloir supprimer cet élément ?",
  btnText = "Supprimer",
  onDelete,
  children,
  renderButton = null,
  widthModal,
  ...props
}: ButtonDeleteProps) {
  const { open, openModal, closeModal } = useModal();
  return (
    <>
      {!!renderButton && renderButton({ openModal })}
      {!renderButton && (
        <Button onClick={openModal} bg="red.main" {...props}>
          {children || "Supprimer"}
        </Button>
      )}
      <Modal open={open} backdrop onClose={closeModal} width={widthModal}>
        <Flex justifyContent="center" alignItems="center" flexDirection="column" p={1}>
          <Box m={2} color="red.main">
            <ImWarning fontSize="50px" />
          </Box>
          <Text>{modalText}</Text>
          <Box p={2} />
          <Flex justifyContent="center" alignItems="center">
            <Button
              bg="red.main"
              onClick={() => {
                onDelete();
                closeModal();
              }}
            >
              {btnText}
            </Button>
            <Box p={1} />
            <Button bg="dark.light" onClick={closeModal}>
              Annuler
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
