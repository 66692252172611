import { useField } from "react-final-form";
import { Label } from "./Label";
import { ErrorField } from "./ErrorField";
import { Box } from "reflexbox";
import { useThemeUI } from "theme-ui";

export type CheckboxProps = {
  name: string;
  label?: string;
  required?: boolean;
  flex?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export function Checkbox({ name, label, required, ...props }: CheckboxProps) {
  const { input, meta } = useField(name);
  const { theme }: any = useThemeUI();

  return (
    <Box>
      <label style={{ display: "flex", alignItems: "center" }}>
        {label && <Label color={props?.color} mb="0">{`${label}${required ? "*" : ""}`}</Label>}
        <input
          type="checkbox"
          {...input}
          style={{
            color: theme?.colors?.primary?.blue_to_gold,
            marginLeft: "5px",
          }}
          defaultChecked={input.value}
          {...props}
        />
      </label>
      <ErrorField meta={meta} />
    </Box>
  );
}
