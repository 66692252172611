import { IoAdd } from "react-icons/io5";
import { Flex } from "reflexbox";
import { Bounced } from "./Bounced";
import { ComponentProps } from "react";

export const ButtonCreate = (props: ComponentProps<typeof Flex>) => (
  <Bounced scale="1.1">
    <Flex
      as="button"
      width="30px"
      height="30px"
      fontSize="28px"
      fontWeight="200"
      justifyContent="center"
      alignItems="center"
      backgroundColor="#CDD1FA"
      color="dark.main"
      sx={{ borderRadius: "50%", cursor: "pointer" }}
      {...props}
    >
      <IoAdd />
    </Flex>
  </Bounced>
);
