import Responsive, { useMediaQuery } from "react-responsive";

export const gutterWidth = 18;
export const contentWidth = 1248;

export const smallBreakpoint = {
  width: 900,
  gutter: 20,
};

export const mediumBreakpoint = {
  width: 1026,
  gutter: 18,
};

export const largeBreakpoint = {
  width: 1290,
  gutter: 18,
};

export const useIsMobile = () => useMediaQuery({ maxWidth: 980 });

export const SmallOnly = (props) => <Responsive {...props} minWidth={0} maxWidth={smallBreakpoint.width - 1} />;
export const MediumUp = (props) => <Responsive {...props} minWidth={smallBreakpoint.width} />;
export const MediumDown = (props) => <Responsive {...props} maxWidth={mediumBreakpoint.width - 1} />;
export const LargeUp = (props) => <Responsive {...props} minWidth={mediumBreakpoint.width} />;

// large
export const isLargeUp = () => useMediaQuery({ maxWidth: 1290 });
export const isLargeDown = () => useMediaQuery({ minWidth: 1290 });
export const isMediumDown = () => useMediaQuery({ minWidth: 1026 });
export const isSmallDown = () => useMediaQuery({ minWidth: 980 });

// hight
export const isHightHeightUp = () => useMediaQuery({ minHeight: 901 });
export const isHightHeightDown = () => useMediaQuery({ maxHeight: 901 });
export const isMediumHeightDown = () => useMediaQuery({ maxHeight: 820 });
export const isLittleHeightDown = () => useMediaQuery({ maxHeight: 750 });

// calc
export function getScaleKards() {
  const isXHigthHeight = isHightHeightUp();
  const isHigthHeight = isHightHeightDown();
  const isMediumHeight = isMediumHeightDown();
  const isLittleHeight = isLittleHeightDown();
  const isxLarge = isLargeUp();
  const isLarge = isLargeDown();
  const isMedium = isMediumDown();
  const isSmall = isSmallDown();

  if (isXHigthHeight && isxLarge) return "scale(1)";
  if (isHigthHeight) return "scale(0.85)";
  if (isMediumHeight) return "scale(0.75)";
  if (isLittleHeight) return "scale(0.7)";
  if (isxLarge) return "scale(1)";
  if (isLarge) return "scale(0.9)";
  if (isMedium) return "scale(0.7)";
  if (isSmall) return "scale(0.6)";
  return "";
}

export function getScaleModal() {
  const isXHigthHeight = isHightHeightUp();
  const isHigthHeight = isHightHeightDown();
  const isMediumHeight = isMediumHeightDown();
  const isLittleHeight = isLittleHeightDown();
  const isxLarge = isLargeUp();
  const isLarge = isLargeDown();
  const isMedium = isMediumDown();
  const isSmall = isSmallDown();

  if (isXHigthHeight) return "scale(1)";
  if (isHigthHeight) return "scale(0.85)";
  if (isMediumHeight) return "scale(0.75)";
  if (isLittleHeight) return "scale(0.7)";
  if (isxLarge) return "scale(1)";
  if (isLarge) return "scale(0.9)";
  if (isMedium) return "scale(0.8)";
  if (isSmall) return "scale(0.7)";
  return "";
}
