import { Establishment } from "@kards/types";
import { useParams } from "react-router";
import { useToken } from "src/app/AppContext";
import { db } from "src/firebase";
import useSWR from "swr";

export function useGetEstablishmentWithToken() {
  const token = useToken();
  const establishmentId = token?.claims?.establishment;
  return useSWR<{ id: string } & Establishment>(`establishments/${establishmentId}`, async () => {
    return db
      .collection("establishments")
      .doc(establishmentId)
      .get()
      .then((doc) => ({ id: doc.id, ...doc.data() } as { id: string } & Establishment));
  });
}

export function useGetEstablishmentWithParams() {
  const { establishmentId } = useParams<{ establishmentId: string }>();
  return useSWR<{ id: string } & Establishment>(`establishments/${establishmentId}`, async () => {
    return db
      .collection("establishments")
      .doc(establishmentId)
      .get()
      .then((doc) => ({ id: doc.id, ...doc.data() } as { id: string } & Establishment));
  });
}
