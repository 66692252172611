import { Text } from "../Text";

type LabelProps = {
  error?: boolean;
  children: React.ReactNode;
} & React.ComponentProps<typeof Text> &
  any;

export function Label({ error, children, color = "label", ...props }: LabelProps) {
  return (
    <Text mb="4px" color={error ? "red.main" : color} fontSize="14px" {...props}>
      {children}
    </Text>
  );
}
