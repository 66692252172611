import { useState } from "react";

export function useModal() {
  const [open, setOpen] = useState(false);

  const openModal = (e?: any) => {
    e?.preventDefault();
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return { open, openModal, closeModal };
}
