import { Component } from "react";
import { Box, Flex } from "reflexbox";
import { Button } from "./Button";
import { Text } from "./Text";
import { firebase } from "../firebase";
import { ReactNode } from "react";

function ErrorFallBack() {
  return (
    <Flex width="100vw" height="100vh" justifyContent="center" alignItems="center" flexDirection="column">
      <Flex
        justifyContent="center"
        alignItems="center"
        sx={{ borderRadius: "50%" }}
        backgroundColor="white.main"
        width="30vh"
        height="30vh"
      >
        <img src="/img/failure.svg" style={{ width: "10vh", height: "10vh" }} />
      </Flex>
      <Box p={1} />
      <Text fontSize="3em">Oups!</Text>
      <Box p={1} />
      <Text>Il semblerait qu'il y ait une erreur</Text>
      <Box p={2} />
      <Box>
        <Button onClick={() => window?.location?.replace("/")}>Retour</Button>
      </Box>
    </Flex>
  );
}

export class ErrorBoundary extends Component<{ fallback?: ReactNode }> {
  state = { error: false };

  componentDidCatch(error, info) {
    console.error(error, info);
    firebase.analytics().logEvent("error_boundary_admin", { description: JSON.stringify(error) });
    this.setState({ error: info.componentStack });
  }

  render() {
    if (this.state.error) return this.props.fallback || <ErrorFallBack />;
    return this.props.children;
  }
}
