import { ComponentProps } from "react";
import { Form as FormFinalForm } from "react-final-form";
import arrayMutators from "final-form-arrays";

export type FormFinalProps<T extends any> = {
  initialValues?: T & { id?: string };
  onSubmit?: (values: T) => void;
  style?: React.CSSProperties;
  onSuccess?: (item: any, values: T) => void;
  resetAfterSubmit?: boolean;
} & Omit<ComponentProps<typeof FormFinalForm>, "onSubmit">;

const setValue = ([name, value], state, { changeValue }) => {
  changeValue(state, name, () => value);
};

const clear = ([name], state, { changeValue }) => {
  changeValue(state, name, () => undefined);
};

export function FormFinal<T extends any>({
  initialValues,
  onSubmit,
  children,
  render,
  resetAfterSubmit,
  style = { width: "100%", height: "100%", overflow: "scroll" },
  ...props
}: FormFinalProps<T>) {
  return (
    <FormFinalForm
      enableReinitialize={false}
      initialValues={initialValues}
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators, setValue, clear }}
      {...props}
    >
      {(childrenProps) => {
        if (typeof render === "function")
          return (
            <form
              style={style}
              onSubmit={(e) => childrenProps.handleSubmit(e)?.then(resetAfterSubmit ? childrenProps.form.reset : null)}
            >
              {render(childrenProps)}
            </form>
          );

        if (typeof children === "function")
          return (
            <form style={style} onSubmit={childrenProps.handleSubmit}>
              {children(childrenProps)}
            </form>
          );

        return (
          <form style={style} onSubmit={childrenProps.handleSubmit}>
            {children}
          </form>
        );
      }}
    </FormFinalForm>
  );
}
