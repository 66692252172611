import { auth, functions } from "src/firebase";

type SignUpProps = {
  establishmentName: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  passwordConfirm: string;
  isGroup?: boolean;
};

export async function signUp(values: SignUpProps) {
  try {
    const signUpCloud = functions.httpsCallable("signUp");
    const signUpCloudFn = await signUpCloud(values);
    if (signUpCloudFn?.data?.code) return { code: signUpCloudFn?.data?.code };
    if (!signUpCloudFn?.data) return { code: "error" };
    await auth.signInWithEmailAndPassword(values.email, values.password);
    await auth?.currentUser?.sendEmailVerification();
    return signUpCloudFn;
  } catch (err) {
    return err;
  }
}
