import styled from "styled-components";
import { Box } from "reflexbox";

export type BoxProps = React.ComponentProps<typeof Box>;

export const Text = (props: BoxProps) => (
  <Box as="p" fontFamily="Poppins" fontSize={2} color="primary.dark_to_white" {...props} />
);

export function TitleMobile({ children, ...props }) {
  return (
    <Text fontWeight="bold" color="white.main" fontSize="4.2vw" sx={{ textTransform: "uppercase" }} {...props}>
      {children}
    </Text>
  );
}

export function TextMobile({ children, ...props }) {
  return (
    <Text color="white.main" fontSize="3.5vw" {...props}>
      {children}
    </Text>
  );
}

export const TextEllipsis = (props: BoxProps) => (
  <Text
    fontFamily="Poppins"
    sx={{
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
    {...props}
  />
);

export const TextLightGreyLight = styled.p<{
  theme?: any;
  fontSize?: string;
  textAlign?: string;
  whiteSpace?: string;
}>`
  ${({ theme, fontSize, textAlign }) => `
    font-weight: 200;
    font-size: ${fontSize ? fontSize : "1em"};
    color: ${theme.colors.secondary.main};
    text-align: ${textAlign ? textAlign : "left"};
  `}
`;

export const TextGreyBold = styled.p<{
  theme?: any;
  fontSize?: string;
  textAlign?: string;
  whiteSpace?: string;
}>`
  ${({ theme, fontSize, textAlign, whiteSpace }) => `
    font-weight: 600;
    font-size: ${fontSize ? fontSize : "1em"};
    color: ${theme.colors.secondary.dark};
    text-align: ${textAlign ? textAlign : "left"};
    white-space: ${whiteSpace};
  `}
`;

export const TextWhite = styled.p<{ fontWeight?: string; textAlign?: string }>`
  ${({ textAlign, fontWeight }) => `
    font-weight: 200;
    font-size: 1em;
    color: white;
    text-align: ${textAlign ? textAlign : "left"};
    font-weight: ${fontWeight || 500};
  `}
`;

// -----------------------------------------------------------------------------------------------
// title -----------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------

export const TitleGreyBold = styled.h2<{
  theme?: any;
  fontSize?: string;
  textAlign?: string;
}>`
  ${({ theme, textAlign, fontSize }) => `
    font-weight: 500;
    font-size: ${fontSize ? fontSize : "1.3em"};
    color: ${theme.colors.secondary.dark};
    text-align: ${textAlign ? textAlign : "left"};
  `}
`;
