import { auth } from "src/firebase";

export async function forgotPassword({ email }) {
  try {
    await auth.sendPasswordResetEmail(email).catch(e => {
      throw e;
    });
  } catch (error) {
    return error;
  }
}
