import { Flex } from "reflexbox";
import { IoCloseSharp } from "react-icons/io5";
import { ComponentProps } from "react";

export function ButtonClose(props: ComponentProps<typeof Flex>) {
  return (
    <Flex
      as="button"
      bg="#EDEEF4"
      width="28px"
      height="28px"
      sx={{ borderRadius: "50%", cursor: "pointer" }}
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <IoCloseSharp fontSize="25px" />
    </Flex>
  );
}
